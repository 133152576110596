import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SignUpEmail from "../components/signUpEmail"
import img1 from "../images/index/index-img-1.svg"
import img2 from "../images/index/index-img-2.svg"
import img3 from "../images/index/index-img-3.svg"
import img4 from "../images/index/index-img-4.png"
import img5 from "../images/index/index-img-5.svg"
import img6 from "../images/index/index-img-6.svg"
import img7 from "../images/index/index-img-7.svg"
import img8 from "../images/index/index-img-8.svg"
import img13 from "../images/index/index-img-13.svg"

const IndexPage = () => (
  <Layout css="indexPageCss">
    <SEO title="Boston Digital Marketing Agency"
         description={"Our Boston Digital Marketing Agency helps businesses with digital marketing and social media across Boston. Contact us for more information."}/>
    <div className={"container hero section-padding-wrapper"}>
      <div className={"row align-items-center m-flex-direction-col-rev"}>
        <div className={"col-md-6"}>
          <h1>INNOVATIVE <span className={"red"}>DIGITAL MARKETING</span> WITH REAL RESULTS</h1>
          <p className={"subtitle"}>Activate your business growth by creating a better digital presence with Avantcy Marketing today.</p>
          <a href={"/contact#contact-today"} className={"button-red"}>REQUEST A CALL</a>
        </div>
        <div className={"col-md-6"}>
          <img alt={""} src={img1} className={"w-100 m-pic-align"}/>
        </div>
      </div>
    </div>
    <div className={"container section-padding-wrapper"}>
      <div className={"text-align-center"}>
        <h2>BOSTON DIGITAL MARKETING AGENCY</h2>
      </div>
      <h3 className={"subtitle"}>Our Boston Digital Marketing Agency helps businesses with digital marketing
        and social media across Boston. Contact us for more information.</h3>
      <div className={"row"}>
        <div className={"col-md-4"}>
          <div className={"img-wrapper flex-vert-center-wrapper"}>
            <img alt={""} src={img2} id={""} className={"w-100 m-pic-align"}/>
          </div>
          <h4>Create Compelling Content For Better Audience Engagements</h4>
          <p className={"text-align-center"}>Are you lost on how to start your site’s content? Your content should
            educate your potential customers while keeping them engaged with your business’ products and services. Let
            Avantcy create compelling website content, blog posts, promotional videos, and podcasting content for your
            brand.</p>
        </div>
        <div className={"col-md-4"}>
          <div className={"img-wrapper flex-vert-center-wrapper"}>
            <img alt={""} src={img3} id={""} className={"w-100 m-pic-align"}/>
          </div>
          <h4>Utilize Effective Social Media Marketing To Build Your Brand’s Image</h4>
          <p className={"text-align-center"}>You don’t need to seek independent social media experts when you can hire a
            social media agency in Boston to manage your social media accounts. Avantcy can also recommend effective
            social media management programs for you if you already have your in-house team.</p>
        </div>
        <div className={"col-md-4"}>
          <div className={"img-wrapper flex-vert-center-wrapper"}>
            <img alt={""} src={img4} id={""} className={"w-100 m-pic-align"}/>
          </div>
          <h4>Attain Efficient Search Engine Optimization (SEO)</h4>
          <p className={"text-align-center"}>May it be organic or local SEO solutions, Avantcy Boston Digital Marketing
            Agency has it all! Increase website visitors, leads, conversions, online sales with Avantcy’s SEO expertise,
            like driving traffic and measuring Return On Investment (ROI).</p>
        </div>
      </div>
      <div className={"row"}>
        <div className={"col-md-4"}>
          <div className={"img-wrapper flex-vert-center-wrapper"}>
            <img alt={""} src={img5} id={""} className={"w-100 m-pic-align"}/>
          </div>
          <h4>Monitor Pay Per Click (PPC) Search Ads</h4>
          <p className={"text-align-center"}>Avantcy, as a marketing company in Boston, also offers Pay Per Click (PPC)
            search ads management. Paying once someone clicks on your site can save costs. Utilize this feature while
            pairing it with Avantcy’s digital marketing experts to monitor and ensure your website will rank.</p>
        </div>
        <div className={"col-md-4"}>
          <div className={"img-wrapper flex-vert-center-wrapper"}>
            <img alt={""} src={img6} id={""} className={"w-100 m-pic-align"}/>
          </div>
          <h4>Improvise Appealing Web Design</h4>
          <p className={"text-align-center"}>Attract your potential customers with eye-pleasing web design to make a
            fantastic first impression! Keep up with the newest web design trend at Avantcy with consistent website
            updates!</p>
        </div>
        <div className={"col-md-4"}>
          <div className={"img-wrapper flex-vert-center-wrapper"}>
            <img alt={""} src={img7} id={""} className={"w-100 m-pic-align"}/>
          </div>
          <h4>Reputation Management</h4>
          <p className={"text-align-center"}>Creating quality leads is a must-included service from any Boston digital
            marketing group, but Avantcy offers more than that. With Avantcy’s digital marketing programs and
            strategies, you can monitor and control your brand’s image to influence potential leads.</p>
        </div>
      </div>
    </div>
    <div className={"background-gray"}>
      <div className={"container section-padding-wrapper"}>
        <div className={"d-flex justify-content-center flex-column-mobile"}>
          <div className={"flex-equal-width m-3"}>
            <img alt={""} src={img8} id={"choosing-pic"}/>
          </div>
          <div className={"flex-equal-width m-3 text-align-center"}>
            <h2>WHY CHOOSE AVANTCY MARKETING</h2>
            <p>Let Avantcy Marketing achieve your business goals as your Boston digital marketing agency. Focusing on
              innovative brand management solutions, we are happy to help you introduce your brand to the world. Out of
              various marketing firms in Boston, Avantcy can help you boost your brand’s online presence with
              cost-efficient strategies. Achieve competent marketing services with us and deliver incredible results for
              your business.</p>
            <a href={"/contact#contact-today"} className={"button-red m-top-20"}>REQUEST A CALL</a>
          </div>
        </div>
      </div>
    </div>
    <div className={"container section-padding-wrapper"}>
      <div className={"text-align-center"}>
        <h2>DIGITAL MARKETING EXPERTS</h2>
      </div>
      <h3 className={"subtitle m-bottom-75"}>Are you looking for Boston digital marketing agency experts? Combining
        Avantcy Marketing’s expertise, hard work, and willingness to push beyond limits, let us become your best
        marketing
        guide.</h3>
      <div className={"row"}>
        <div className={"col-md-6 p-left-85"}>
          <h4 id={"img9"} className={"img-before"}>Most Suitable Strategy</h4>
          <p>Upon knowing your brand at every level, Avantcy Marketing can create the most suitable digital marketing
            strategy. Our digital marketing experts will form a scalable marketing approach that fits your business
            goals and marketing assets. Combining your brand’s competence and effective marketing, grow your web
            presence while elevating your brand with Avantcy Marketing.</p>
        </div>
        <div className={"col-md-6 p-left-85"}>
          <h4 id={"img10"} className={"img-before"}>Trendy Web Design</h4>
          <p>After defining a suitable strategy for you, Avantcy Marketing will help you create an impactful brand story
            through compelling web design. Besides visual identity, we enables you to have a user-friendly site with a
            responsive and up-to-date design trend.</p>
        </div>
      </div>
      <div className={"row m-top-20"}>
        <div className={"col-md-6 p-left-85"}>
          <h4 id={"img11"} className={"img-before"}>Breakthrough Website Campaigns</h4>
          <p>While keeping your business goals in mind, we can develop fast loading websites even on your busiest
            days. Avantcy Marketing is a Boston digital marketing agency having top-tier performance in generating
            consistent quality leads.</p>
        </div>
        <div className={"col-md-6 p-left-85"}>
          <h4 id={"img12"} className={"img-before"}>Passionate Digital Marketing Team</h4>
          <p>We firmly believes that a dedicated digital marketing expert team can conquer any digital marketing
            conflict. While keeping composure and expertise, our team would love to work with you at the best of
            your convenience.</p>
        </div>
      </div>
    </div>
    <div className={"container section-padding-wrapper"}>
      <div className={"d-flex justify-content-center flex-column-mobile"}>
        <div className={"flex-equal-width m-3 text-align-center"}>
          <h2>ABOUT AVANTCY MARKETING</h2>
          <p>It is time for you to step up the game by generating an innovative digital marketing strategy! We
            understands how competition in digital marketing becomes tougher with the frequent shifting of people's
            preferences. Contact us today and allow our team to scale your business through our innovative and
            up-to-date marketing approach.</p>
          <a href={"/contact#contact-today"} className={"button-red m-top-20"}>REQUEST A CALL</a>
        </div>
        <div className={"flex-equal-width m-3"}>
          <img alt={""} src={img13} id={"choosing-pic"}/>
        </div>
      </div>
    </div>
    {/*<div className={"background-gray"}>
      <div className={"container section-padding-wrapper"}>
        <SignUpEmail/>
      </div>
    </div>*/}
  </Layout>
);

export default IndexPage
